import React, { useState } from 'react'
import { Router } from '@reach/router'
import PrivateLayout from '../components/PrivateLayout'
import { MySavings, AddSavingsBucket, SavingsBucket } from '../components/savings'
import { BudgetForm } from '../components/budget'
import { MyFinancialHealth } from '../components/financialHealth'
import { MyFuneral, NewFuneralPolicy, MyFuneralPolicy, FuneralQuickQuote, MyThirdPartyFuneralPolicy } from '../components/funeral'
import { MyInsurance, MyCreditLife, MyLoan, CreditLifeQuickQuote } from '../components/insurance'
import { ClearanceCertificateQuickQuote, ClearanceCertificatePayment, MyClearanceCertificate } from '../components/clearanceCertificate'
import { NewEmployer, MyEmployers } from '../components/employer'
import { ProfileMenu, MyProfile, BankAccounts, BankAccount, DocumentVault, DocumentRouter } from '../components/profile'
import { MyDashboard } from '../components/dashboard'
import PrivateRoute from '../components/PrivateRoute'
import PrivateEmployeeRoute from '../components/PrivateEmployeeRoute'
import { navigate } from 'gatsby'
import ErrorPage from '../components/pages/ErrorPage';
import { DebtRepaymentPage } from '../components/debtRepayments'
import DebtQualifying from '../components/leads/DebtQualifying'
import GroupDetails from '../components/group/GroupDetails' 
import SEO from '../components/seo'
import { MyGroupFuneral } from '../components/groupFuneral'
import GroupContribution from '../components/group/GroupContribution/GroupContribution'
import MyGroup from '../components/group/MyGroup'
import GroupProductContributions from '../components/group/GroupProductContributions'

function PrivatePages() {
  const [showSideBar, setShowSideBar] = useState(true)
  const [modalOpen, setModalOpen] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)

  if (typeof window === "undefined") {
    return <div />
  }

  let redirect = new URLSearchParams(window.location.search).get('redirect')

  if (redirect) {
    navigate(`/${redirect}`)
    return <div />
  }

  const PageNotFoundError = () => <ErrorPage showButton={false} message={<>404<br/> Page not found</>}/>

  return <PrivateLayout showSideBar={showSideBar} selectedIndex={selectedIndex}>
    <SEO title="MyMeerkat Secure Site" />
    <Router>
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/" component={MyFinancialHealth} setSelectedIndex={setSelectedIndex} index={0} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/welcome" component={MyFinancialHealth} welcome setSelectedIndex={setSelectedIndex} index={0} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/debt" component={MyDashboard} setSelectedIndex={setSelectedIndex} index={1} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/profile" component={ProfileMenu} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/profile/edit" component={MyProfile} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/documents" component={DocumentVault} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/documents/*" component={DocumentRouter} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/savings" component={MySavings} setSelectedIndex={setSelectedIndex} index={2} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/bank-accounts" component={BankAccounts} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/bank-accounts/link" component={BankAccounts} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/bank-account/:id" component={BankAccount} setSelectedIndex={setSelectedIndex} index={4} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/funeral" component={MyFuneral} />
      <CreditLifeQuickQuote showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/credit-life/qq" open={modalOpen} setOpen={setModalOpen} />
      {/*<FuneralQuickQuote showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/funeral/qq" open setOpen={() => {}} />*/ }
      <ClearanceCertificateQuickQuote showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/clearance-certificate/qq" open setOpen={() => {}} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/clearance-certificate/:id" component={MyClearanceCertificate} setSelectedIndex={setSelectedIndex} index={0} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/funeral/:id" component={MyFuneralPolicy} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/group" component={MyGroup} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/group/:id" component={GroupDetails} /> 
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/group-members/:id" component={MyGroupFuneral} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/contributions/:id" component={GroupContribution} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/group-contribution/:id" component={GroupProductContributions} />      
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/third-party-funeral/:id" component={MyThirdPartyFuneralPolicy} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/insurance" component={MyInsurance} setSelectedIndex={setSelectedIndex} index={3} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/credit-life" component={MyCreditLife} setSelectedIndex={setSelectedIndex} index={3} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/loan/:id" component={MyLoan} setSelectedIndex={setSelectedIndex} index={3} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/employer" component={MyEmployers} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/employer/add" component={NewEmployer} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/savings/add" component={AddSavingsBucket} setSelectedIndex={setSelectedIndex} index={2} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/savings/add/:bucketType" component={AddSavingsBucket} setSelectedIndex={setSelectedIndex} index={2} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/savings/:id" component={SavingsBucket} setSelectedIndex={setSelectedIndex} index={2} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/budget" component={BudgetForm} setSelectedIndex={setSelectedIndex} index={3} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/debt-repayments" component={DebtRepaymentPage} setSelectedIndex={setSelectedIndex} index={1} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/debt-repayments/:category" component={DebtRepaymentPage} setSelectedIndex={setSelectedIndex} index={1} />
      <PrivateEmployeeRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/group-members-details/:id" component={MyGroupFuneral} />
      <PrivateEmployeeRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/contributions-details/:id" component={GroupContribution} />
      <PrivateEmployeeRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="/group-contribution-details/:id" component={GroupProductContributions} />
      <PrivateRoute showSideBar={showSideBar} setShowSideBar={setShowSideBar} path="*" component={PageNotFoundError} setSelectedIndex={setSelectedIndex} index={3} />
    </Router>
  </PrivateLayout>
}

export default PrivatePages