import React, { useState } from 'react'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { ActivityIndicator, Text, Button } from '../../generic'
import { useQuery, useMutation } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import gql from 'graphql-tag'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import DetailedItemCard from '../../dashboard/DetailedItemCard'
import { BackActionHeader } from '../../shared'
import { navigate } from 'gatsby'
import moment from 'moment'
import GroupRemoveMember from '../GroupRemoveMember/GroupRemoveMember'

const GET_CONTRIBUTION = gql`query ($id:String!) {
  groupcontribution (id:$id) {
    id
    isPaid
    period
    groupId
    groupProductId
    contributions {
      amount
      client {
        firstNames
        surname
      }
      associatedProducts {
        productId
        productType
        amount
      }
    }
  }
}`

const GET_EMPLOYEE_INFO = gql`{
  me {
    id
  }
}`

function GroupProductContributions({ id }) {
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [removeMember, setRemoveMember] = useState(false)
  const { loading, data, error } = useQuery(GET_CONTRIBUTION, { variables: { id } })
  const { data: employeeData, loading: employeeLoading } = useQuery(GET_EMPLOYEE_INFO)


  const removeGroupProductMember = (contribution) => {
    setRemoveMember({
      id: contribution.associatedProducts[0].productId,
      name: `${contribution.client.firstNames || ""} ${contribution.client.surname || ""}`
    })
    setConfirmOpen(true)
  }


  if (loading || employeeLoading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }
  const { contributions, isPaid, period, groupProductId } = data && data.groupcontribution || {}

  return <>
    <BackActionHeader title="Contributions" onClick={() => employeeData.me ? navigate(`/contributions-details/${groupProductId}`) : navigate(`/contributions/${groupProductId}`)} />

    <Text size={26} bold>Group Contribution</Text>

    <SummaryItemsRow>
      <SummaryItemCard title="Total Members" description="Contributing" value={contributions.length} color="text" />
      <SummaryItemCard title="Total Payment" description={`Due: ${period ? moment(period).format("MMM YYYY") : ''}`} value={printCurrency((contributions || []).reduce((total, contribution) => total + contribution.amount || 0, 0))} color="text" />
      <SummaryItemCard title="Paid:" value={isPaid ? 'Yes' : 'No'} color="text" />
    </SummaryItemsRow>
    <p />

    <Text size={16} bold>Contributors</Text>
    <p />
    {(contributions || []).map(contribution => {
      const commonProps = { key: contribution.client.firstNames, title: `${contribution.client.firstNames || ""} ${contribution.client.surname || ""}`, value: contribution.amount }
      return isPaid ? 
      <DetailedItemCard {...commonProps} /> :
      <DetailedItemCard {...commonProps} functionDescription={"Remove"} functionColor={"alertRed"} onClick={() => removeGroupProductMember(contribution)} />
    })}
    <p />
    <p />
    <p />
    <GroupRemoveMember open={confirmOpen} setOpen={setConfirmOpen} refetchQueries={[{ query: GET_CONTRIBUTION, variables: { id } }]} id={removeMember.id} removeMember={removeMember} />
    <div style={{ height: "75px" }} />
  </>
}

export default GroupProductContributions
