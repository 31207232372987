import React, { useState } from "react"
import { BackActionHeader } from '../../shared'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, SelectableItem, Text } from '../../generic'
import { navigate } from 'gatsby'
import { LoanInfoContainer, Separator } from "../../insurance/MyLoan/MyLoan.style"
import { InfoRow } from "../../insurance/MyLoan/MyLoan"

const GET_GROUP = gql`
  query GetGroup($id: String!) {
    group(id: $id) {
      id
      name
      postalCode
      email
      roleHolders {
       role
       client {
         firstNames
          surname
        }
      }
      products {
        id
        groupProductNumber
        state
      }
    }
  }
`
const GET_EMPLOYEE_INFO = gql`{
  me {
    id
  }
}`

function GroupDetails({ id }) {
  const { data, loading, error } = useQuery(GET_GROUP, { variables: { id } })
  const { data: employeeData, loading: employeeLoading } = useQuery(GET_EMPLOYEE_INFO)

  if (loading || employeeLoading) {
    return <ActivityIndicator />
  }
  const { group } = data
  const { name, postalCode, email, roleHolders } = group

  const roleMap = {}

  roleHolders.forEach(({ role, client }) => {
    if (["CHAIRPERSON", "TREASURER", "SECRETARY"].includes(role)) {
      roleMap[role] = `${client.firstNames || ''} ${client.surname || ''}`
    }
  })
  
  return <div>

    {employeeData.me && <BackActionHeader title="Group Sales" onClick={() => navigate('/group-sales')} />}
    {data && <BackActionHeader title="Group" onClick={() => navigate('/group')} />}

    <Text size={16} bold>My Group</Text>
    <p />
    <LoanInfoContainer>
      <InfoRow title={name} titleColor="primary" />
      <Separator />
      <InfoRow title="Chairperson" value={roleMap.CHAIRPERSON} />
      <InfoRow title="Treasurer" value={roleMap.TREASURER} />
      <InfoRow title="Secretary" value={roleMap.SECRETARY} />
    </LoanInfoContainer>
    <p />

    {(group.products || []).map(groupProduct => 
      <SelectableItem key={groupProduct.id} title={`Meerkat Policy - ${groupProduct.groupProductNumber}`} arrow leftIndicatorBarColor="primary" onClick={() => employeeData.me ? navigate(`/group-members-details/${groupProduct.id}`) : navigate(`/group-members/${groupProduct.id}`)} /> 
    )}
  </div>
}

export default GroupDetails