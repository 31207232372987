import React, { useState } from 'react'
// import {} from './MyFuneral.style'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { ActivityIndicator, Text, Button } from '../../generic'
import { useQuery } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import gql from 'graphql-tag'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import FuneralItemCard from '../FuneralItemCard'
import FuneralCoverItemRow from '../FuneralCoverItemRow'
import { BackActionHeader, TitleImage, BlogPostCard, MokuNotePanel } from '../../shared'
import { navigate } from 'gatsby'
import FuneralQuickQuote from '../FuneralQuickQuote'
import ThirdPartyFuneralWizard from '../ThirdPartyFuneralWizard'
import { rollingMoney } from '../../../images'
import { BlogCardsContainer } from '../../shared/BlogPostCard/BlogPostCard.style'
import parkBench from '../../../images/blogPosts/parkBench.webp'
import dirtCoffin from '../../../images/blogPosts/dirtCoffin.jpeg'
import { BlogPostCards } from '../../sections'

const MY_FUNERAL = gql`{
  myClient {
    id
    funeralPolicies {
      id
      policyNumber
      productType
      state
      coverAmount
      policyType
      currentPremiumTotal
      livesCovered {
        memberType
        coverAmount
      }
      #claimPayments {
      #  amount
      #}
    }
    thirdPartyFuneralPolicies {
      id
      funeralPolicyInfo {
        companyName
        coverAmount
        premiumAmount
        livesCovered {
          memberType
        }
      }
    }
  }
}`

const memberTypeDescription = {
  POLICY_HOLDER: "Me",
  SPOUSE: "My Spouse",
  CHILD: "My Children",
  PARENT: "My Parents",
  EXTENDED: "My Extended Family"
}

function MyFuneral() {
  const [open, setOpen] = useState(false)
  const [addExisting, setAddExisting] = useState(false)
  const [showPolicies, setShowPolicies] = useState(true)
  const { data, loading, error } = useQuery(MY_FUNERAL)

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }

  const funerals = [] // (data && data.myClient && data.myClient.funeralPolicies) || []
  const thirdPartyFunerals = [] // (data && data.myClient && data.myClient.thirdPartyFuneralPolicies && data.myClient.thirdPartyFuneralPolicies.map(d => ({ ...d.funeralPolicyInfo, id: d.id }))) || []
  /*const funerals = [{
    currentPremiumTotal: 275,
    coverAmount: 20000,
    livesCovered: [{
      memberType: "POLICY_HOLDER"
    }, {
      memberType: "CHILD"
    }, {
      memberType: "EXTENDED"
    }]
  }]*/

  /*const thirdPartyFunerals = [{
    companyName: "Old Mutual",
    premiumAmount: 275,
    coverAmount: 10000,
    livesCovered: [{
      memberType: "POLICY_HOLDER"
    }, {
      memberType: "SPOUSE"
    }, {
      memberType: "EXTENDED"
    }]
  }, {
    companyName: "Clientele",
    premiumAmount: 375,
    coverAmount: 5000,
    livesCovered: [{
      memberType: "EXTENDED"
    }]
  }, {
    companyName: "Hollard",
    premiumAmount: 375,
    coverAmount: 30000,
    livesCovered: [{
      memberType: "PARENT"
    }]
  }]*/

  const coverByType = {}
  const meerkatCoverByType = {}

  const meerkatPoliciesByType = {}
  const policiesByType = {}
  const coverTypeMissing = {}

  const blogPosts = [
    {
      imageSrc: parkBench,
      title: 'What is the best funeral cover in South Africa?',
      description: 'The best funeral cover options in South Africa. How do you begin to choose a funeral policy that is perfectly suited for your needs and budget?',
      linkUrl: 'https://www.meerkat.co.za/blog/what-is-the-best-funeral-cover-in-south-africa',
    },
    {  
      imageSrc: dirtCoffin,
      title: 'Looking for affordable funeral cover? ',
      description: 'Are you looking for affordable funeral cover in South Africa? Read our latest blog post for what to look for when you are considering your options.',
      linkUrl: 'https://www.meerkat.co.za/blog/news/affordable-funeral-cover',
    },
  ];

  funerals.forEach(({ livesCovered, coverAmount: policyCoverAmount }) => {
    const memberTypes = {}
    livesCovered.forEach(({ memberType, coverAmount }) => {
      memberTypes[memberType] = true
      if (!coverByType[memberType]) {
        coverByType[memberType] = 0
        meerkatCoverByType[memberType] = 0
      }
      coverByType[memberType] += (coverAmount || policyCoverAmount || 0)
      meerkatCoverByType[memberType] += (coverAmount || policyCoverAmount || 0)
    })
    Object.keys(memberTypes).forEach(memberType => {
      if (!policiesByType[memberType]) {
        meerkatPoliciesByType[memberType] = 0
        policiesByType[memberType] = 0
      }
      meerkatPoliciesByType[memberType] += 1
      policiesByType[memberType] += 1
    })
  })

  thirdPartyFunerals.forEach(({ livesCovered, coverAmount: policyCoverAmount }) => {
    const memberTypes = {}
    livesCovered.forEach(({ memberType, coverAmount }) => {
      memberTypes[memberType] = true
      if (!coverByType[memberType]) {
        coverByType[memberType] = 0
      }
      coverByType[memberType] += (coverAmount || policyCoverAmount || 0)
      if (!coverAmount && !policyCoverAmount) {
        coverTypeMissing[memberType] = true
      }
    })
    Object.keys(memberTypes).forEach(memberType => {
      if (!policiesByType[memberType]) {
        policiesByType[memberType] = 0
      }
      policiesByType[memberType] += 1
    })
  })

  const getMemberTypeColor = memberType => {
    if (policiesByType[memberType] > 1) {
      if (coverTypeMissing[memberType]) {
        return "alertRed"
      }
      return "alertAmber"
    }
    if (coverTypeMissing[memberType]) {
      return "alertRed"
    }
    if (meerkatPoliciesByType[memberType]) {
      return "primary"
    } 
    return "alertAmber"
  }

  const descriptionByState = {
    "CANCELLED": "Cancelled Policy",
    "ON_RISK_GRACE": "Missed Payment - Monthly Contribution",
    "LAPSED": "Lapsed - Monthly Contribution"
  }

  return <>
    <FuneralQuickQuote open={open} setOpen={setOpen} />
    <ThirdPartyFuneralWizard clientId={data && data.myClient && data.myClient.id}  open={addExisting} setOpen={setAddExisting} refetchQueries={[{ query: MY_FUNERAL }]} />
    <BackActionHeader title="My Insurance" onClick={() => navigate('/insurance')} />
    <SummaryItemsRow>
      <SummaryItemCard title="Total Policies" description="" value={funerals.length + thirdPartyFunerals.length} color="text" />
      <SummaryItemCard title="Monthly Contributions" description="" value={printCurrency(funerals.filter(f => f.state !== "CANCELLED").reduce((total, funeral) => total + funeral.currentPremiumTotal || 0, 0) + thirdPartyFunerals.reduce((total, funeral) => total + funeral.premiumAmount || 0, 0))} color="text"/>
    </SummaryItemsRow>
    <p/>
    {funerals.length === 0 && <MokuNotePanel title="Save with Meerkat" description="Get a personalised quote for your funeral cover today. We can also consolidate any existing policies into one more affordable premium.">
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center" }}><Button onClick={() => setOpen(true)}>Get a Quick Quote</Button></div>
    </MokuNotePanel>}
    <p />
    {funerals.length > 0 && <Text size={16} bold>My Meerkat Funeral Policies</Text>}
    {funerals.map(funeral => <FuneralItemCard key={funeral.id} title={`Meerkat Policy - ${funeral.policyNumber}`} description={descriptionByState[funeral.state] || "Monthly Contribution"} premiumAmount={funeral.currentPremiumTotal} onClick={() => navigate(`/funeral/${funeral.id}`)}  />)}
    <p />
    {thirdPartyFunerals.length === 0 && <MokuNotePanel title="Add Existing Cover" description="Add your existing cover and we’ll compare to our funeral cover to see if we can save you money each month. We’ll also be able to provide a summary of all your funeral polices in one place.">
      <div style={{ display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "center" }}><Button onClick={() => setAddExisting(true)}>Add Existing Policy</Button></div>
    </MokuNotePanel>}
    {thirdPartyFunerals.length > 0 && <Text size={16} bold>My Other Existing Policies</Text>}
    {thirdPartyFunerals.map((funeral, i) => <FuneralItemCard key={i} title={`${funeral.companyName} Policy`} description="Monthly Contribution" premiumAmount={funeral.premiumAmount} onClick={() => navigate(`/third-party-funeral/${funeral.id}`)} />)}
    <p />
    <Text size={16} bold>My Funeral Cover</Text>
    {Object.keys(memberTypeDescription).map(memberType => <FuneralCoverItemRow key={memberType} title={memberTypeDescription[memberType]} value={coverByType[memberType] || 0} />)}
    <p />
    <p/>
    <TitleImage title="Keeping you on-track" description="Here are some financial tips from us to help you reach your financial goals." image={rollingMoney} />
    <p />
    <BlogPostCards blogs={blogPosts}/>
    <p />
    <center>
      <Button inverted onClick={() => setAddExisting(true)}>Add Existing Policy</Button>
      <p/>
      <Button onClick={() => setOpen(true)}>Get a Quick Quote</Button>
    </center>
    <div style={{ height: "75px" }} />
  </>
}

export default MyFuneral
