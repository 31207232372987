import React from 'react'
import { Router } from '@reach/router'
import { TermsAndConditions, GetDocumentViaUrl, OtherDocument, DocumentFolder, ProfilePicture, AddNewDocument, GetVaccinationCertificate } from '../documents'
import { API_URL } from '../../../config'

function DocumentRouter() {
  return (
    <Router basepath="/documents">
      <TermsAndConditions path="/tc" />
      <OtherDocument path="/funeral/letter/:id" title="Funeral Policy Letter" />
      <GetDocumentViaUrl
        path="/funeral/:id/schedule"
        url={({ id }) => `${API_URL}funeral/${id}/schedule`}
        title="Policy Schedule"
        fileName="Policy Schedule.pdf"
        auth
      />
      <GetDocumentViaUrl
        path="/funeral/:id/policy"
        url={({ id }) => `${API_URL}funeral/${id}/policy`}
        title="Policy Document"
        fileName="Policy Document.pdf"
        auth
      />
      <GetDocumentViaUrl
        path="/cli/:id/coi"
        url={({ id }) => `${API_URL}creditLife/${id}/coi`}
        fileName="COI.pdf"
        auth
      />
      <GetDocumentViaUrl
        path="/cli/:id/policy"
        url={({ id }) => `${API_URL}creditLife/${id}/car`}
        fileName="CAR.pdf"
        auth
      />
      <OtherDocument path="/clearance-certificate/:id" title="Clearance Certificate" /> 
      <OtherDocument path="/do/:id" title="Debit Order Mandate" />
      <OtherDocument path="/group-document/:id" title="Group Document" />
      <DocumentFolder path="/id" title="RSA Identity Documents" type="RSA_ID" />
      <AddNewDocument path="/id/add" type="RSA_ID" subPage="id" />
      <OtherDocument path="/id/:id" title="RSA Identity Document" />
      <GetVaccinationCertificate path="/vaccine/add" />
      <OtherDocument path="/vaccine/:id" title="Vaccination Certificate" />
      <DocumentFolder path="/vaccine" title="Proof of Vaccination" type="COVID19_VACCINATION_CERTIFICATE" />
      <DocumentFolder path="/payslip" title="My Payslips" type="PAYSLIP" />
      <AddNewDocument path="/payslip/add" type="PAYSLIP" subPage="payslip" />
      <OtherDocument path="/payslip/:id" title="My Payslip" />
      <OtherDocument path="/hyphenStatement/:id" title="Hyphen Statements" type="HYPHEN" />
      <DocumentFolder path="/hyphenStatement" title="Hyphen Statements" type="HYPHEN" />
      <ProfilePicture path="/selfie" title="My Selfie" />
    </Router>
  )
}

export default DocumentRouter
