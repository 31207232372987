import React, { useState } from 'react'
// import {} from './MyFuneral.style'
import { SummaryItemsRow } from '../../dashboard/MyDashboard/MyDashboard.style'
import { ActivityIndicator, Text, Button, SelectableItem } from '../../generic'
import { useQuery, useMutation } from 'react-apollo'
import { printCurrency } from '../../../lib/utils'
import gql from 'graphql-tag'
import moment from 'moment'
import SummaryItemCard from '../../dashboard/SummaryItemCard'
import FuneralItemCard from '../../funeral/FuneralItemCard'
import FuneralCoverItemRow from '../../funeral/FuneralCoverItemRow'
import { BackActionHeader, TitleImage, MokuNotePanel, ConfirmModal } from '../../shared'
import { navigate } from 'gatsby'
import { rollingMoney } from '../../../images'
import parkBench from '../../../images/blogPosts/parkBench.webp'
import dirtCoffin from '../../../images/blogPosts/dirtCoffin.jpeg'
import { BlogPostCards } from '../../sections'
import UploadMembersFile from '../UploadMembersFile'
import { LoanInfoContainer, Separator } from '../../insurance/MyLoan/MyLoan.style'
import { InfoRow } from '../../insurance/MyLoan/MyLoan'
import DetailedItemCard from '../../dashboard/DetailedItemCard'

const GROUP_FUNERAL = gql`query ($id:String!) {
  groupproduct(id:$id) {
    id
    groupProductNumber
    state
    processedUntil
    group {
      name
    }
    funerals {
      id
      client {
        firstNames
        surname
			}
      state
      currentPremiumTotal
      policyType
      livesCovered {
        memberType
      }
		}
  }
}`

const GET_EMPLOYEE_INFO = gql`{
  me {
    id
  }
}`

const memberTypeDescription = {
  POLICY_HOLDER: "Me",
  SPOUSE: "My Spouse",
  CHILD: "My Children",
  PARENT: "My Parents",
  EXTENDED: "My Extended Family"
}

function GroupFuneral({ id }) {
  const [open, setOpen] = useState(false)
  const { data, loading, error } = useQuery(GROUP_FUNERAL, { variables: { id }})
  const { data: employeeData, loading: employeeLoading } = useQuery(GET_EMPLOYEE_INFO)

  if (loading || employeeLoading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }

  const funerals = (data && data.groupproduct && data.groupproduct.funerals) || []
  const activeFunerals = funerals.filter((f) => ['ACTIVE','DORMANT'].includes(f.state))

  const policyDescription = (funeral) => {    
    let description = ""
    if ((funeral.policyType || "").includes("COUPLE")) {
        description = "Family"
    } else {
      description = "Single Member"
    }
    if (funeral.livesCovered.filter(l => l.memberType === "EXTENDED").length > 0) {
      description += " and " + (funeral.livesCovered.filter(l => l.memberType === "EXTENDED").length) + " adult dependants"
    }
    return description
  }

  var claims = []

  const groupProduct = data && data.groupproduct
  const { group, processedUntil } = groupProduct || {}

  return <>
    <UploadMembersFile open={open} setOpen={setOpen} groupProductNumber={data && data.groupproduct && data.groupproduct.groupProductNumber} />
    {employeeData.me ? <BackActionHeader title="Group Sales" onClick={() => navigate('/group-sales')} /> : <BackActionHeader title="Group" onClick={() => navigate('/group')} />}
    <Text size={16} bold>My Group Funeral Policy</Text>
    <SummaryItemsRow>
      <SummaryItemCard title="Total Members" description="Active" value={funerals.filter(f => f.state === "ACTIVE" || f.state === "DORMANT" || f.state === "INFO_MISSING").length} color="text" />
      <SummaryItemCard title="Total Claims" description="To Date" value={printCurrency(claims.reduce((total, c) => total + c.amountClaimed, 0))} color="text"/>
      <SummaryItemCard title="Next Payment" description={processedUntil ? moment(processedUntil).format("YYYY-MM-DD") : ""} value={printCurrency(funerals.filter(f => f.state === "ACTIVE" || f.state === "DORMANT" || f.state === "INFO_MISSING").reduce((total, f) => total + f.currentPremiumTotal, 0))} color="text"/>
    </SummaryItemsRow>
    <p />
    <LoanInfoContainer>
      <InfoRow title={<Text>Status: <Text span bold>{groupProduct.state}</Text></Text>} />
      <Separator />
      <InfoRow title="Group Name" value={group.name} />
      <InfoRow title="Policy Number" value={groupProduct.groupProductNumber} />
    </LoanInfoContainer>
    <p/>
    {employeeData.me ? <SelectableItem key={group.id} title={'Contributions'} arrow onClick={() => navigate(`/contributions-details/${id}`)} /> : <SelectableItem key={group.id} title={'Contributions'} arrow onClick={() => navigate(`/contributions/${id}`)} />}
    <p/>
    <Text size={16} bold>My Group Members</Text>
    {(activeFunerals).map(funeral => <DetailedItemCard key={funeral.id} description={policyDescription(funeral)} title={`${funeral.client.firstNames || ""} ${funeral.client.surname || ""}`}  value={printCurrency(funeral.currentPremiumTotal)} />)}
    <p />
    <p />
    <center>
      <p/>
      { employeeData.me && <Button onClick={() => setOpen(true)}>Upload Members Sheet</Button>}
    </center>
    <div style={{ height: "75px" }} />
  </>
}
export default GroupFuneral
