import React, { useState } from 'react'
import { ActivityIndicator, Text, SelectableItem } from '../../generic'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { BackActionHeader, TitleImage, BlogPostCard, MokuNotePanel } from '../../shared'
import { navigate } from 'gatsby'
import { rollingMoney } from '../../../images'
import parkBench from '../../../images/blogPosts/parkBench.webp'
import dirtCoffin from '../../../images/blogPosts/dirtCoffin.jpeg'
import { BlogPostCards } from '../../sections'

const MY_GROUP = gql`{
  myClient {
    id
    groups {
      id
      name
    }
  }
}`


function MyGroup() {
  const { data, loading, error } = useQuery(MY_GROUP)

  if (loading) {
    return <ActivityIndicator />
  }

  if (error) {
    return "Something has gone wrong. Please contact support"
  }

  const groups = (data && data.myClient && data.myClient.groups) || []

  const blogPosts = [
    {
      imageSrc: parkBench,
      title: 'What is the best funeral cover in South Africa?',
      description: 'The best funeral cover options in South Africa. How do you begin to choose a funeral policy that is perfectly suited for your needs and budget?',
      linkUrl: 'https://www.meerkat.co.za/blog/what-is-the-best-funeral-cover-in-south-africa',
    },
    {  
      imageSrc: dirtCoffin,
      title: 'Looking for affordable funeral cover? ',
      description: 'Are you looking for affordable funeral cover in South Africa? Read our latest blog post for what to look for when you are considering your options.',
      linkUrl: 'https://www.meerkat.co.za/blog/news/affordable-funeral-cover',
    },
  ];

  return <>
    <BackActionHeader title="My Insurance" onClick={() => navigate('/insurance')} />
    <p />
    {groups.length > 0 && <Text size={16} bold>My Meerkat Funeral Groups</Text>}
    {groups.map(group => <SelectableItem key={group.id} title={`${group.name}`} arrow leftIndicatorBarColor="primary" onClick={() => navigate(`/group/${group.id}`)}  />)}
    <p />
    <p />
    <p/>
    <TitleImage title="Keeping you on-track" description="Here are some financial tips from us to help you reach your financial goals." image={rollingMoney} />
    <p />
    <BlogPostCards blogs={blogPosts}/>
    <p />
    <center>
    </center>
    <div style={{ height: "75px" }} />
  </>
}

export default MyGroup
