import React from 'react'
import { WizardModal } from '../../shared'
import { isRequired } from '../../../lib/validators'
import gql from 'graphql-tag'

const REMOVE_GROUP_PRODUCT_MEMBER = gql`
  mutation ($funeralId: String!, $cancelReason: String!) {
    cancelFuneral(funeralId: $funeralId, cancelReason: $cancelReason) {
      id
    }
  }
`

const buildSteps = (setOpen, refetchQueries, memberName) => ([
  {
    name: "getConfirmation",
    title2: "Remove Member",
    title3: `Are you sure you want to remove ${memberName}?`,
    nextButtons: [{ label: "Yes", nextSteps: ["reason"] }, { label: "Cancel", nextSteps: ["done"], buttonProps: { inverted: true, noBorder: true } }],
    nextButtonsInColumn: true,
  },
  {
    name: "reason",
    title: "Remove Member",
    fieldNames: ["cancelReason"],
    nextSteps: ["removeMember"]
  },
  {
    beforeStep: async ({ apolloClient, chatState }) => {
      const { cancelReason, funeralId } = chatState
      const result = await apolloClient.mutate({ mutation: REMOVE_GROUP_PRODUCT_MEMBER, variables: { cancelReason, funeralId }, refetchQueries })
      console.log(result)
    },
    name: "removeMember",
    noNext: true,
    nextAfterDelay: 1000,
    nextSteps: ["done"]
  },
  {
    name: "done",
    beforeStep: () => setOpen(false)
  }
])

const fields = {
  cancelReason: {
    label: "Removal Reason",
    required: true,
    type: "select",
    options: ["Policyholder has claimed", "Fraud Suspected", "Client Affordability", "Client changed their mind", "Client Age", "Other"],
    validate: [isRequired]
  },
}

function GroupRemoveMember({ open, setOpen, id, refetchQueries, removeMember }) {
  return <WizardModal
    name={`groupRemoveMember-${removeMember.id}`}
    open={open}
    setOpen={setOpen}
    steps={buildSteps(setOpen, refetchQueries, removeMember.name)}
    initialValues={{ funeralId: id }}
    fields={fields}
  />
}

export default GroupRemoveMember
