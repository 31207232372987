import React from 'react'
// import {} from './DocumentVault.style'
import { TitleImage, BackActionHeader } from '../../shared'
import { sittingOnMoney } from '../../../images'
import { ActivityIndicator, Text } from '../../generic'
import { Row } from '../../generic/grid'
import DocumentGridItem from '../DocumentGridItem'
import DocumentListItem from '../DocumentListItem'
import { navigate } from 'gatsby-link'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

export const GET_MY_DOCUMENTS = gql`{
  myClient {
    id
    funeralPolicies {
      id
      policyNumber
    }
    creditLifePolicies {
      id
      policyNumber
    }
    documents {
      id
      type
      name
      description
    }
  }
}`


const documentParams = [
  { type: "SELFIE", title: "My Selfie", link: "/documents/selfie", noIdParam: true, alwaysShow: true },
  { type: "RSA_ID", title: "SA ID", link: "/documents/id", alwaysShow: true },
  { type: "COVID19_VACCINATION_CERTIFICATE", title: "Proof of Vaccination", link: "/documents/vaccine", alwaysShow: true },
  { type: "CLEARANCE_CERTIFICATE", title: "Clearance Certificate", link: "/documents/clearance-certificate", },
  { type: "HYPHEN", title: "Hyphen Statements", link: "/documents/hyphenStatement", },
  { title: "Drivers Licence" },
  { title: "My Bank Statements" },
  { title: "My Payslips", type: "PAYSLIP", alwaysShow: true, link: "/documents/payslip" , noIdParam: true},
  { title: "Credit Agreement Statement" },
  { title: "Retrenchment Letter" },
  { title: "UI-19" },
  { title: "Medical Aid Statement" },
  { type: "OTHER", title: "Other" }
]

const groupDocumentTypes = [
  "MEERKAT_GROUP_WAITING_PERIOD_ACCEPTANCE",
  "MEERKAT_GROUP_ADDENDUM_TO_CONSTITUTION",
  "MEERKAT_GROUP_FUNERAL_CONSTITUTION",
  "MEERKAT_GROUP_FUNERAL_POLICY_DOCUMENT",
]

export const documentParamsByType = {}
documentParams.filter(d => d.type).forEach((docType) => documentParamsByType[docType.type] = docType)

function DocumentVault() {
  const { loading, error, data } = useQuery(GET_MY_DOCUMENTS)

  if (loading || error) {
    return <>
      <BackActionHeader title="My Profile" onClick={() => navigate('/profile')} />
      <TitleImage title="Document Vault" description="The document vault keeps all your documents safe and makes it easy for you to access when ever you need to." image={sittingOnMoney} />
      {loading && <ActivityIndicator />}
      {!loading && error}
    </>
  }

  const documentGrid = []
  const groupDocuments = []
  const doMandates = data.myClient.documents.filter(d => d.type === "do")
  const { creditLifePolicies, funeralPolicies, documents } = data.myClient

  const docIdsByType = {}
  documents.forEach(d => {
    if (d.type) {
      if (!docIdsByType[d.type]) {
        docIdsByType[d.type] = []
      }
      docIdsByType[d.type].push(d.id)
    }
  })

  documents.forEach(d => {
    if (groupDocumentTypes.includes(d.type)) {
      groupDocuments.push(d)
    }
  })

  documentParams.forEach(doc => {
    if (doc.type && docIdsByType[doc.type]) {
      documentGrid.push({
        title: doc.title,
        link: doc.noIdParam || docIdsByType[doc.type].length > 1 ? doc.link : `${doc.link}/${docIdsByType[doc.type][0]}`
      })
    } else if (doc.alwaysShow) {
      documentGrid.push({
        title: doc.title,
        link: doc.noIdParam ? doc.link : `${doc.link}/add`
      })
    }
  })

  return <>
    <BackActionHeader title="My Profile" onClick={() => navigate('/profile')} />
    <TitleImage title="Document Vault" description="The document vault keeps all your documents safe and makes it easy for you to access when ever you need to." image={sittingOnMoney} />
    <Text size={16} bold>Terms and Conditions</Text>
    <DocumentListItem title="Accepted Terms and Conditions" onClick={() => navigate('/documents/tc')} />
    <p />
    <Text size={16} bold>My Credit Life Policies</Text>
    {creditLifePolicies.length === 0 ? "None" : creditLifePolicies.map(({ id, policyNumber }) => <>
      <DocumentListItem title={`${policyNumber} Certificate of Insurance`} onClick={() => navigate(`/documents/cli/${id}/coi`)} />
      <DocumentListItem title={`${policyNumber} Policy Document`} onClick={() => navigate(`/documents/cli/${id}/policy`)} />
    </>)}
    <p />
    <Text size={16} bold>My Funeral Policies</Text>
    {funeralPolicies.length === 0 ? "None" : funeralPolicies.map(({ id, policyNumber }) => <>
      <DocumentListItem title={`${policyNumber} Policy Schedule`} onClick={() => navigate(`/documents/funeral/${id}/schedule`)} />
      <DocumentListItem title={`${policyNumber} Policy Document`} onClick={() => navigate(`/documents/funeral/${id}/policy`)} />
    </>)}
    <p />
    {/*<Text size={16} bold>Tax Certificates</Text>
    <DocumentListItem title="Tax_2020/2020_name" />
    <p />*/}
    <Text size={16} bold>Debit Order Mandates</Text>
    {doMandates.length > 0 ?
      doMandates.map(({ id }, i) => <DocumentListItem key={i} title="DO_Mandate" onClick={() => navigate(`/documents/do/${id}`)} />)
    : "None" }
    <p />
    {groupDocuments.length > 0 && 
    <>
      <Text size={16} bold>Funeral Group Documents</Text>
      {groupDocuments.map(({ id, name }, i) => <DocumentListItem key={i} title={name} onClick={() => navigate(`/documents/group-document/${id}`)} />)}
      <p />
    </>}
    <Text size={16} bold>My Documents</Text>
    <Row wrap>
      {documentGrid.map(({ title, link }, i) => <DocumentGridItem key={i} title={title} onClick={() => link && navigate(link) } />)}
    </Row>
    <div style={{ height: "100px" }} />
  </>
}

export default DocumentVault
