import React from 'react'
// import {} from './MyInsurance.style'
import { Text, SelectableItem, ActivityIndicator } from '../../generic'
import { TitleImage } from '../../shared'
import { InfoIconWithModal } from '../../shared/infoModal/InfoIcon'
import { sittingOnMoney, rollingMoney } from '../../../images'
import coverYourHome from '../../../images/blogPosts/coverYourHome.webp'
import dirtCoffin from '../../../images/blogPosts/dirtCoffin.jpeg'
import { navigate } from 'gatsby'
import { BlogPostCards } from '../../sections'
import GoalOption from '../../savings/GoalOption'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'

const blogPosts = [
  {
    imageSrc: coverYourHome,
    title: 'Insure Your Debt For When Life Happens',
    description: 'Be prepared for when life happens to you. Meerkat is looking out for you, make sure you have the insurance that you need for your debt.',
    linkUrl: 'https://www.meerkat.co.za/blog/news/insurance',
  },
  {  
    imageSrc: dirtCoffin,
    title: 'Looking for affordable funeral cover? ',
    description: 'Are you looking for affordable funeral cover in South Africa? Read our latest blog post for what to look for when you are considering your options.',
    linkUrl: 'https://www.meerkat.co.za/blog/news/affordable-funeral-cover',
  }
]

const MY_INSURANCE = gql`{
  myClient {
    id
    funeralPolicies {
      id
    }
    thirdPartyFuneralPolicies {
      id
    }
    creditLife {
      id
    }
    groups {
      id
    }
  }
}`

function MyInsurance() {
  const { data, loading, error } = useQuery(MY_INSURANCE)

  if (loading) return <ActivityIndicator large />

  const { myClient } = data
  const hasFuneral = myClient && (myClient.funeralPolicies.length > 0 || myClient.thirdPartyFuneralPolicies.length > 0)
  const hasMeerkatFuneral = myClient && (myClient.funeralPolicies.length > 0)
  const hasMeerkatGroup = myClient && (myClient.groups.length > 0)
  const hasMeerkatCreditLife = false // myClient && (myClient.creditLife) 

  return <>
    <TitleImage title="My Insurance" description="Plan ahead and provide your family with peace of mind. Get the right policy with us." image={sittingOnMoney} />
    {(hasFuneral || hasMeerkatCreditLife) && <Text bold size={16} padBottom="8px">My Policies</Text>}
    {hasFuneral && <SelectableItem title="My Funeral Policies" arrow leftIndicatorBarColor="primary" onClick={() => navigate('/funeral')} />}
    {hasMeerkatCreditLife && <SelectableItem title="My Credit Life Insurance" arrow leftIndicatorBarColor="primary" onClick={() => navigate('/credit-life')} />}
    <p />
    {(hasMeerkatGroup) && <Text bold size={16} padBottom="8px">Funeral Groups</Text>}
    {hasMeerkatGroup && <SelectableItem title="My Funeral Groups" arrow leftIndicatorBarColor="primary" onClick={() => navigate('/group')} />}
    <p />
    {(!hasMeerkatCreditLife || !hasMeerkatFuneral) && <>
      <Text bold size={16}>Insurance to help you save</Text>
      <Text size={13} padTop="8px">Meerkat provides affordable credit life and funeral insurance solutions with an easy online sign up and claims process.</Text>
      <Text size={13} padTop="8px">Get a Quote Today!</Text>
      <p />
      {!hasMeerkatCreditLife &&<GoalOption title={<>Credit Life Insurance <InfoIconWithModal title="What is Credit Life Insurance?" description="Credit life insurance provides cover on your unsecured loans in the event of death, temporary and permanent disability, critical illness, and retrenchment" /></>} description="Your loans are covered, and Creditors paid directly when the unexpected happens." onClick={() => {  navigate('/credit-life') }} />}
      {!hasMeerkatFuneral && <GoalOption title="Funeral Insurance" description="Get funeral cover or consolidate multiple funeral policies into one more affordable premium." onClick={() => { navigate('/funeral')  }} />}
      <p />
    </>}
    <TitleImage title="Keeping you on-track" description="Here are some financial tips from us to help you reach your financial goals." image={rollingMoney} />
    <p />
    <BlogPostCards blogs={blogPosts}/>
    <p />
  </>
}

export default MyInsurance
